/*
Name:      Shogo
Use with:  jQuery
Version:   0.4.7 (12.09.2012)
Author:    Grigory Zarubin, Andrey Sidorov, Sergey Glagolev (Shogo.RU)


Различный функционал, используемый на наших сайтах.
*/

//-----------------------------------------------------------------------------
// this_url
//-----------------------------------------------------------------------------
var this_url = document.location.href.replace(/#.*$/, '')+(document.location.href.match(/\?/) == null ? '?' : '&' )+'$js='+(new Date()).valueOf();
//-----------------------------------------------------------------------------
// Element's Check
//-----------------------------------------------------------------------------
var gbi = function(el) { return !!document.getElementById(el); };
//-----------------------------------------------------------------------------
// $HAR(resp)
//-----------------------------------------------------------------------------
function $HAR(resp) // (handle ajax response) стандартный вывод сообщений об ошибках
{
  var result = false;
  var defmsg = 'Произошла неизвестная ошибка!';
  if( resp && resp.status )
  {
    switch( resp.status )
    {
      case 'ok':
        result = true;
        break;

      case 'error':
        alert(resp.message || defmsg);
        break;

      default:
        alert(defmsg);
    }
    if( resp.evaluate )
      eval(resp.evaluate);
  }
  return result;
}
//-----------------------------------------------------------------------------
// number_format
//-----------------------------------------------------------------------------
function number_format( number, decimals, dec_point, thousands_sep, decimals_force ) {
  var i, j, kw, kd, km;

  // input sanitation & defaults
  if( isNaN(decimals = Math.abs(decimals)) ){
    decimals = 0;//or 2 or 3...после запятой
  }
  if( dec_point == undefined ){
    dec_point = ",";
  }
  if( thousands_sep == undefined ){
    thousands_sep = " ";
  }

  i = String(parseInt(number = (+number || 0).toFixed(decimals)));

  if( (j = i.length) > 3 ){
    j = j % 3;
  } else{
    j = 0;
  }

  km = (j ? i.substr(0, j).concat(thousands_sep) : "");
  kw = i.substr(j).replace(/(\d{3})(?=\d)/g, "$1".concat(thousands_sep));
  if( decimals ) {
    kd = Math.abs(number - i).toFixed(decimals).replace(/-/, 0).slice(2);

    if( !kd.match(/^0+$/) || decimals_force )
      kd = dec_point.concat(kd);
    else
      kd = "";
  }
  else
    kd = "";

  return km.concat(kw, kd);
}
//-----------------------------------------------------------------------------
// Advanced preventDefault()
// USAGE: if($.preventDefaultEvent(e)) return;
//-----------------------------------------------------------------------------
jQuery.preventDefaultEvent = function(e, options) {
  options = options || {shift:1, ctrl:1, alt:1, meta:1};
  var href = e.currentTarget.getAttribute('href');
  if(((options.shift && e.shiftKey)
      || (options.alt && e.altKey)
      || (options.ctrl && e.ctrlKey)
      || (options.meta && e.metaKey))
      && href && href.indexOf('#') != 0
      && href.indexOf('javascript:') != 0
  ) return true;
  e.preventDefault();
  return false;
};
//-----------------------------------------------------------------------------
// Some strings extensions
//-----------------------------------------------------------------------------
String.prototype.find = function(string) {
  return (this.indexOf(string) !== -1 ? true : false);
};

// Encodes special characters into HTML entities
String.prototype.escapeHtml = function() {
  var map = {
    '&': '&amp;',
    '<': '&lt;',
    '>': '&gt;',
    '"': '&quot;',
    "'": '&#039;'
  };

  return this.replace(/[&<>"']/gi, function(m) { return map[m]; });
};

// Strip HTML and PHP tags from a string
String.prototype.stripTags = function() {
	return this.replace(/<\/?[^>]+>/gi, '');
};
//-----------------------------------------------------------------------------