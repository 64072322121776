var CALLBACKS = {};

function checkResponse(response, form) {
  if (response === undefined || response === null)
    return;

  if (response.status == 'ok') {
    if (response.updateElements !== undefined)
      updateElements(response.updateElements);

    if (response.hideElements !== undefined)
      hideElements(response.hideElements);

    if (response.showElements !== undefined)
      showElements(response.showElements);

    if (response.removeElements !== undefined)
      removeElements(response.removeElements);

    if (response.callbacks !== undefined)
      callCallbacks(response.callbacks);


    if (response.reload !== undefined)
      location.reload();

    if (response.redirect !== undefined)
      location.href = response.redirect;

    if (response.message !== undefined)
      alert(response.message);


    if (form !== undefined) {
      if (response.validateErrors !== undefined) {
        show_yii_errors_messages(form, $.parseJSON(response.validateErrors), response.scrollOnFirstError);
        form.data('settings').submitting = false;
      }

      if (response.messageForm !== undefined) {
        form.addClass('success').html('<div id=\'form-success-message\'>' + response.messageForm + '</div>');

        if (response.scrollOnMessage === true)
          $DOCUMENT.trigger('scrollTo', [form.find('#form-success-message'), 0]);
      }
    }
  }

  $.mouseLoader(false);
}

function updateElements(data) {
  for (var i in data) {
    if (data.hasOwnProperty(i)) {
      selector = '.' + i + ',#' + i;

      if ($(selector).is('input'))
        $(selector).val(data[i]);
      else if ($(selector).hasClass('text'))
        $(selector).text(data[i]);
      else
        $(selector).replaceWith(data[i]);
    }
  }
}

function hideElements(data) {
  for (var i in data) {
    if (data.hasOwnProperty(i)) {
      selector = '.' + data[i] + ',#' + data[i];
      if ($(selector).length)
        $(selector).hide();
    }
  }
}

function showElements(data) {
  for (var i in data) {
    selector = '.' + data[i] + ',#' + data[i];

    if (data.hasOwnProperty(i))
      if ($(selector).length)
        $(selector).show();
  }
}

function removeElements(data) {
  for (var i in data) {
    if (data.hasOwnProperty(i))
      if ($('.' + data[i]).length)
        $('.' + data[i]).remove();
  }
}

function callCallbacks(data) {
  for (var i in data) {
    if (CALLBACKS && CALLBACKS[i] !== undefined)
      CALLBACKS[i](data[i]);
  }
}

function show_yii_errors_messages(form, messages, scrollOnFirstError) {
  var data = form.data(),
    firstError;

  form.yiiactiveform.updateSummary(form, messages);

  $.each(data.settings.attributes, function() {
    if ($('#' + this.errorID).length) {
      if (form.yiiactiveform.updateInput(this, messages, form) && !firstError)
        firstError = this;

      delete messages[this.id];
    }
  });

  if (scrollOnFirstError)
    $DOCUMENT.trigger('scrollTo', [$.fn.yiiactiveform.getInputContainer(firstError, form).closest('.form-row')]);

  var error = [];
  for (var i in messages)
    if (messages.hasOwnProperty(i))
      error.push(messages[i].join('\n'));

  if (error.length)
    alert(error.join('\n'));
}